import React, { useContext, useRef, useState } from "react"
import Hero from "~/components/configurable/Hero"
import Block from "~/components/configurable/Block"
import Heading from "~/components/configurable/Heading"
import Section from "../configurable/Section"
import Col12 from "../grid/Col12"
import "./terms_and_conditions.scss"
import Col9 from "../grid/Col9"
import Col3 from "../grid/Col3"
import "./animation.scss"
import Col5 from "../grid/Col5"
import Col6 from "../grid/Col6"
import BlockCTA from "../configurable/BlockCTA"
import Col7 from "../grid/Col7"
import { GetModal } from "../configurable/Modal"



const TnC = () => {

  return (
    <div className="refer">
      <Hero imageUrl="/images/banner_new_2.png" compact>
        <Heading level={1} underlined>
          Warwickshire Agreement
        </Heading>
      </Hero>
      <Block>
        <Section>
          <div className="container">
            <div>
              <Heading level={3} underlined>
              Solar Together Customers T&C
              </Heading>
              <div>
                <meta httpEquiv="Content-Type" content="text/html; charset=windows-1252" />
                <meta name="Generator" content="Microsoft Word 15 (filtered)" />
                <style dangerouslySetInnerHTML={{ __html: "\n<!--\n /* Font Definitions */\n @font-face\n\t{font-family:\"Cambria Math\";\n\tpanose-1:2 4 5 3 5 4 6 3 2 4;}\n@font-face\n\t{font-family:Calibri;\n\tpanose-1:2 15 5 2 2 2 4 3 2 4;}\n /* Style Definitions */\n p.MsoNormal, li.MsoNormal, div.MsoNormal\n\t{margin-top:0cm;\n\tmargin-right:0cm;\n\tmargin-bottom:.65pt;\n\tmargin-left:72.4pt;\n\ttext-align:justify;\n\ttext-justify:inter-ideograph;\n\ttext-indent:-28.6pt;\n\tline-height:112%;\n\tfont-size:10.0pt;\n\tfont-family:\"Arial\",sans-serif;\n\tcolor:#1C1C1C;}\nh1\n\t{mso-style-link:\"Heading 1 Char\";\n\tmargin-top:0cm;\n\tmargin-right:0cm;\n\tmargin-bottom:0cm;\n\tmargin-left:45.5pt;\n\ttext-indent:-.5pt;\n\tline-height:107%;\n\tpage-break-after:avoid;\n\tfont-size:10.0pt;\n\tfont-family:\"Arial\",sans-serif;\n\tcolor:#181818;}\na:link, span.MsoHyperlink\n\t{color:#0563C1;\n\ttext-decoration:underline;}\nspan.Heading1Char\n\t{mso-style-name:\"Heading 1 Char\";\n\tmso-style-link:\"Heading 1\";\n\tfont-family:\"Arial\",sans-serif;\n\tcolor:#181818;\n\tfont-weight:bold;}\n.MsoChpDefault\n\t{font-family:\"Calibri\",sans-serif;}\n.MsoPapDefault\n\t{margin-bottom:8.0pt;\n\tline-height:107%;}\n /* Page Definitions */\n @page WordSection1\n\t{size:595.55pt 842.05pt;\n\tmargin:56.65pt 44.1pt 44.1pt 17.9pt;}\ndiv.WordSection1\n\t{page:WordSection1;}\n@page WordSection2\n\t{size:595.55pt 842.05pt;\n\tmargin:72.0pt 68.05pt 72.0pt 17.9pt;}\ndiv.WordSection2\n\t{page:WordSection2;}\n /* List Definitions */\n ol\n\t{margin-bottom:0cm;}\nul\n\t{margin-bottom:0cm;}\n-->\n" }} />
                <div className="WordSection1">
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '141.7pt', marginBottom: '1.8pt', marginLeft: '43.0pt', textIndent: '-.4pt', lineHeight: '107%' }}><b>Terms and
                    conditions for customers in the group purchase scheme Solar Together </b><b><span style={{ color: '#080808' }}>Warwickshire </span>2022</b><b><span style={{ color: 'black' }}> </span></b></p>
                  <p className="MsoNormal" style={{ margin: '0cm', textAlign: 'left', textIndent: '0cm', lineHeight: '107%' }}><b><span style={{ color: 'black' }}>&nbsp;</span></b></p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '7.0pt', marginBottom: '0cm', marginLeft: '43.0pt', textIndent: '-.4pt', lineHeight: '107%' }}><b>Definitions</b><b><span style={{ color: '#444444' }}>:</span></b><span style={{ color: 'black' }}> </span></p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '43.55pt', textIndent: '0cm' }}>In these terms and conditions, the
                    following words have the following meanings: </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '.95pt', marginLeft: '0cm', textAlign: 'left', textIndent: '0cm', lineHeight: '107%' }}><span style={{ color: 'black' }}>&nbsp;</span></p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '1.95pt', marginLeft: '43.55pt', textIndent: '0cm' }}><b>Acceptance Deposit: </b>the amount
                    payable on acceptance of the Personal Recommendation totalling £150. </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '2.4pt', marginLeft: '72.15pt' }}><b>Additional Costs: </b>those situations set out in the
                    “Additional Costs” document, which forms part of the Contract. </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '72.15pt' }}><b>Agreement: </b>together, these Terms and Conditions,
                    the Contract, the Personal Recommendation and all other documents referred to
                    in these terms and conditions.<span style={{ color: 'black' }}> </span></p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '25.9pt', marginBottom: '.65pt', marginLeft: '43.55pt', textIndent: '0cm' }}><b>Completion: </b>the System has
                    been installed, connected, commissioned and is generating electricity<span style={{ color: '#2F2F2F' }}>. </span><b>Contract</b><span style={{ color: 'black' }}>: The
                      form of acceptance you receive after you have accepted the Personal
                      Recommendation </span><b>Customer or you: </b>the Registrant who has accepted a
                    Personal Recommendation.<span style={{ color: 'black' }}> </span></p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '72.15pt' }}><b>Customer Personal Data: </b>all personal data (as
                    defined by Data Protection Law) belonging to the Customer and provided to the
                    Supplier under this Agreement<span style={{ color: '#2F2F2F' }}>.</span><span style={{ color: 'black' }}> </span></p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '43.55pt', textIndent: '0cm' }}><b>Customer's Property: </b>the property
                    at the address specified in the Contract.<span style={{ color: 'black' }}> </span></p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '72.15pt' }}><b>Data Protection Law: </b>any law, statute, regulation,
                    rule or other binding restriction regarding the protection of individuals with
                    regards to the processing of their personal data to which the Supplier is
                    subject, including the Data Protection Act 2018 and the General Data Protection
                    Regulation (regulation 2016/679) and any code of practice or guidance published
                    by the Information Commissioner's Office from time to time.<span style={{ color: 'black' }}> </span></p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '43.55pt', textIndent: '0cm' }}><b>Delivery and Delivered: </b>the supply,
                    installation, connection and commissioning of the System<span style={{ color: '#2F2F2F' }}>.</span><b><span style={{ color: 'black' }}> </span></b></p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '.65pt', marginBottom: '.1pt', marginLeft: '71.25pt', textAlign: 'left', textIndent: '-28.8pt', lineHeight: '117%' }}><b><span style={{ color: 'black' }}>Home Insulation &amp; Energy
                    Systems (HIES): </span></b>HIES is a consumer protection organisation covering
                    the installation of renewable energy and home energy efficiency products. HIES
                    is approved by the Chartered Trading Standards Institute (CTSI) under the
                    Consumer Codes Approval Scheme. </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '72.15pt' }}><b>Personal Recommendation: </b>the offer the Registrant
                    receives after the auction either electronically (for those Registrants who
                    registered electronically) or by post (for those Registrants who registered by
                    post) for the supply and installation of a System.<span style={{ color: 'black' }}> </span></p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '2.5pt', marginLeft: '72.15pt' }}><b>Privacy Policy: </b>the policy located at <a href="https://get-uk.com/privacy">https://get-uk.com/privacy</a>,
                    setting out certain details in respect of Customer Personal Data, as required
                    by the Data Protection Legislation. </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '8.95pt', marginBottom: '.1pt', marginLeft: '71.25pt', textAlign: 'left', textIndent: '-28.8pt', lineHeight: '117%' }}><b>Installation Deposit Payment:</b> An amount totalling no
                    more than 25% of the total amount payable by the Customer minus the Acceptance
                    Deposit, chargeable only after Survey and on confirmation of an installation
                    appointment. The Installation Deposit Payment must be registered and protected
                    through a recognised scheme, and the Customer must receive a Deposit Protection
                    Certificate. </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '72.15pt' }}><b>Registrant:</b><b><span style={{ color: '#444444' }}> </span></b>a
                    person who registered with iChoosr Limited in a group purchase scheme to be
                    contacted by a supplier after iChoosr Limited has hosted an auction whereby
                    suppliers bid to enter into contracts with those persons for the supply of a
                    System. </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '.65pt', marginBottom: '.1pt', marginLeft: '71.25pt', textAlign: 'left', textIndent: '-28.8pt', lineHeight: '117%' }}><b>Renewable Energy Consumer Code</b><b><span style={{ color: '#444444' }}>: </span></b>the code sponsored by the Renewable Energy
                    Association and currently administered by Renewable Energy Assurance Limited.
                    The aim of the Consumer Code is to guarantee a high-quality experience for
                    consumers wishing to buy or lease small-scale energy generation systems for their
                    homes. The Code is approved by the Chartered Trading Standards Institute (CTSI)
                    under the Consumer Codes Approval Scheme.&nbsp; Members of RECC agree to comply with
                    the Code.&nbsp; </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '43.55pt', textIndent: '0cm' }}><b>Specification: </b>The specification
                    and description of the System as set out in the Personal Recommendation and
                    (Revised) Contract. </p>
                  {/* <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '7.85pt', marginBottom: '.1pt', marginLeft: '71.25pt', textAlign: 'left', textIndent: '-28.8pt', lineHeight: '117%' }}><b>Supplier: </b>NIC-6004, an MCS accredited installer, which
                    has acquired the right as the winner of the auction for the group purchase
                    scheme of solar panels to make a Personal Recommendation to the allocated registrants.
                  </p> */}
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '43.55pt', textIndent: '0cm' }}><b>Survey: </b>means the desktop survey
                    and/or the on-site survey carried out by the Supplier. </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '72.15pt' }}><b>Survey </b><b><span style={{ color: '#080808' }}>Report: </span></b>a
                    report prepared by the Supplier regarding the suitability of the Customer's
                    Property for the System and the Works. </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '43.55pt', textIndent: '0cm' }}><b>System: </b>a solar photovoltaic system
                    or a storage system and all ancillary equipment as set out in the Contract </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '43.55pt', textIndent: '0cm' }}><b><span style={{ color: '#080808' }}>Works</span></b><b><span style={{ color: '#2F2F2F' }}>: </span></b>any works required to the Customer' s
                    Property in order to install the System. </p>
                  <p className="MsoNormal" style={{ margin: '0cm', textAlign: 'left', textIndent: '0cm', lineHeight: '107%' }}><span style={{ color: 'black' }}>&nbsp;</span></p>
                  <p className="MsoNormal" style={{ marginLeft: '43.8pt', textAlign: 'left', textIndent: '0cm' }}><b>These terms and conditions set out the obligations and
                    liabilities of the Supplier and the </b></p>
                  <p className="MsoNormal" style={{ marginLeft: '43.8pt', textAlign: 'left', textIndent: '0cm' }}><b>Customer. They contain limitations on the liability of the
                    Supplier to the Customer and the Customer's attention is drawn, in particular,
                    to clause 9. The Customer's attention is also drawn to clauses 2.9 – 2.16&nbsp;
                    which set out the circumstances in which the Acceptance Deposit is non-refundable.
                  </b></p>
                  <p className="MsoNormal" style={{ margin: '0cm', textAlign: 'left', textIndent: '0cm', lineHeight: '107%' }}><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;</b><br clear="all" style={{ pageBreakBefore: 'always' }} />
                  </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '487.95pt', marginBottom: '0cm', marginLeft: '42.75pt', textAlign: 'left', textIndent: '0cm', lineHeight: '125%' }}><b>&nbsp;</b><b><span style={{ color: '#181818' }}>&nbsp;</span></b></p>
                  <h1 style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '2.1pt', marginLeft: '0cm', textIndent: '0cm' }}><span style={{ fontSize: '11.0pt', lineHeight: '107%', fontFamily: '"Calibri",sans-serif', color: 'black', fontWeight: 'normal' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>1.
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;APPLICATION </h1>
                  <p className="MsoNormal" style={{ margin: '0cm', textAlign: 'left', textIndent: '0cm', lineHeight: '107%' }}><b><span style={{ color: 'black' }}>&nbsp;</span></b></p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '72.15pt' }}><span style={{ color: '#2D2D2D' }}>1.1. </span>You should read
                    these terms and conditions carefully. If you have any questions do not hesitate
                    to contact us, using the e-mail address or phone number provided<span style={{ color: '#181818' }}>.</span><span style={{ color: '#2D2D2D' }}> </span></p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '72.15pt' }}>1.2. You should check that the details on the Personal
                    Recommendation are accurate and complete before you accept (sign) the Contract.
                    If you think that there is a mistake, please contact the Supplier before
                    submitting the contract. </p>
                  <p className="MsoNormal" style={{ marginLeft: '0cm', textAlign: 'left', textIndent: '0cm' }}><span style={{ fontSize: '11.0pt', lineHeight: '112%', fontFamily: '"Calibri",sans-serif', color: 'black' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>1.3. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The
                    legal relationship between the Customer and the Supplier is governed by this
                    Agreement.&nbsp; </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '3.3pt', marginLeft: '72.15pt', lineHeight: '92%' }}>1.4. &nbsp;&nbsp; If any provision of these terms
                    and conditions is held to be void or not binding by a competent court, the
                    remaining provisions shall continue to apply and remain in full force. </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '72.15pt' }}>1.5. The Personal Recommendation is open for acceptance by
                    you during the period stated in the Personal Recommendation and, if you wish to
                    accept the Personal Recommendation, the Contract together with the Acceptance
                    Deposit must be received by the Supplier within this period. </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '72.15pt' }}>1.6. The Contract will come into effect on receipt by the
                    Supplier of the Contract (submitted either electronically and digitally signed
                    by the Customer or by post, signed by the Customer (depending on how the
                    Contract was first received)) and the Acceptance Deposit. Payment of the
                    Acceptance Deposit is a valid way to legally sign the Contract. If the Deposit
                    is not received in cleared funds within 10 days of the date of receipt of the
                    Contract, the Contract shall be deemed to have been cancelled by the Customer
                    and the Personal Recommendation will lapse. </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '72.15pt' }}>1.7. The Supplier may terminate this Agreement without
                    cause at any time before the Customer has received the Survey Report from the
                    Supplier on giving the Customer 5 days written notice. In these circumstances
                    the Customer will not be entitled to any damages or compensation from the
                    Supplier and the Supplier will promptly return the Acceptance Deposit to the
                    Customer. In this event, the Supplier shall promptly repay to the Customer any
                    monies paid by the Customer to the Supplier. If the Survey identifies specific
                    risk of adverse effects in continuing with Delivery and the Customer wishes to
                    continue, the Supplier may request from the Customer to sign a waiver for
                    liability. </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '72.15pt' }}>1.8. The Supplier may subcontract its obligations under
                    this Agreement to a Microgeneration Certification Scheme certified third party
                    but shall always remain responsible for performance of this Agreement. All the
                    works under this Agreement shall be carried out by trained and competent
                    personnel. The Supplier will comply with and require any subcontractor of the
                    Supplier to comply with the Home Insulation &amp; Energy Systems (HIES).</p>
                  <p className="MsoNormal" style={{ margin: '0cm', textAlign: 'left', textIndent: '0cm', lineHeight: '107%' }}><span style={{ color: 'black' }}>&nbsp;</span></p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '4.05pt', marginLeft: '0cm', textAlign: 'left', textIndent: '0cm', lineHeight: '107%' }}><span style={{ color: 'black' }}>&nbsp;</span></p>
                  <h1 style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '2.85pt', marginLeft: '0cm', textIndent: '0cm' }}><span style={{ fontSize: '11.0pt', lineHeight: '107%', fontFamily: '"Calibri",sans-serif', color: 'black', fontWeight: 'normal' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span style={{ color: '#2D2D2D' }}>2. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>RECOMMENDATION, ORDERS, PRICES AND
                    CANCELLATIONS<span style={{ color: '#2D2D2D' }}> </span></h1>
                  <p className="MsoNormal"  style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '55.3pt', textAlign: 'center', textIndent: '0cm', lineHeight: '107%' }}><span style={{ color: '#B6B6B6' }}>| <i></i></span><i><span style={{ color: 'black' }}> </span></i></p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '1.7pt', marginLeft: '72.15pt' }}>2.1. The prices stated in the Personal Recommendation and
                    this Agreement are inclusive of VAT. Changes in the VAT rate are passed on to
                    the Customer. </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '72.15pt' }}>2.2. The Specification and pricing in the Personal
                    Recommendation is based on the assumptions (if any) set out in the Personal
                    Recommendation and on the assumption that there are no unspecified
                    circumstances in the Personal Recommendation (including situations described in
                    the “Additional Costs” document) affecting the Customer's Property. If these
                    assumptions are incorrect, the </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '73.35pt', textIndent: '0cm' }}>Supplier may send a revised Contract <b><span style={{ color: '#2D2D2D' }}>("Revised Contract</span></b><b><span style={{ color: '#181818' }}>") </span></b>to the Customer and in this case the
                    Contract formed on the acceptance of the original Personal Recommendation will
                    automatically terminate without either the Customer or the Supplier having any
                    liability to the other. The Customer may, at its discretion, accept the Revised
                    Contract within the period for acceptance specified in the Revised Contract. </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '72.15pt' }}>2.3. The Personal Recommendation can include charges that
                    have been added provisionally and are subject to Survey<span style={{ color: '#181818' }}>
                      <b>("Provisional Charge"). </b></span>Based on the details provided
                    by the Registrant, a Provisional Charge is assumed necessary, but final
                    assessment is subject to Survey. If, as a result of the Survey, a Provisional
                    Charge is determined unnecessary, the original contract remains valid, </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '2.1pt', marginLeft: '73.8pt', textIndent: '0cm' }}>and the Provisional Charge determined
                    unnecessary will be subtracted from the invoice. The Survey Report will address
                    those Provisional Charges which are not necessary for performing this
                    Agreement. For example, if the Customer selected “slates” as a roof material
                    during registration, a charge will be added to the Personal Recommendation. If
                    after the Survey the tiles are determined as concrete tiles, the Provisional
                    charge added for "slates" will be subtracted. </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '72.15pt' }}>2.4. The Customer can still add elements to this Agreement
                    after the Customer has accepted the Personal Recommendation. If the Customer
                    wants to add a product, work or custom work, (such as, but not limited to)
                    extra panels, battery storage, power-diverter, bird netting or optimisers, they
                    can ask to have this added to the Contract. This Agreement remains valid and
                    the Supplier will inform the Customer if it is possible to make changes to the
                    Agreement. If it is possible, the Supplier will inform the Customer about<span style={{ color: '#2A2A2A' }}> </span>any changes to the price, the timing of supply
                    and/or delivery of the System or anything else which would be necessary as a
                    result of the Customer's requested change and ask the Customer if they wish to
                    go ahead with the change. </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '72.15pt' }}>2.5. If there is a change in the law after the date of the
                    contract, which was not taken into account in the Contract and the Works have
                    not been commenced, the Supplier may notify the Customer of an increase in the
                    price to reflect the effect on the Supplier of the change in law. If the price
                    increase is not acceptable to the Customer, the Customer may, prior to the
                    Works commencing, by notice in writing to the Supplier terminate this Agreement
                    and neither the Customer or the Supplier shall have any liability to the other
                    as a result of such termination provided that the Supplier will promptly refund
                    to the Customer any amounts paid by the Customer to the Supplier. If the change
                    in law occurs after the Works have commenced and after the Cancellation Period
                    set out in clause 2.11 below, the Supplier shall have no right to increase the
                    price and the Customer shall have no right to terminate this Agreement. </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '72.15pt' }}>2.6. The Supplier will indemnify the Customer against any
                    claim by HM Revenue and Customs or other competent authority that the Customer
                    is liable to make any payment in respect of the importation into the United
                    Kingdom of any part of the System. </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '72.15pt' }}>2.7. The Supplier gives no warranty that the current
                    grants, financial incentives or any favorable tax measures are available to the
                    Customer or will continue to be available to the Customer. </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '69.05pt' }}>2.8. A charge in addition to the price stated in the
                    Personal Recommendation<span style={{ color: '#2A2A2A' }}> <b>("Additional
                      Charge") </b></span>may be charged for an additional product, work or
                    custom work if: </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '111.35pt', textIndent: '-42.35pt' }}>2.8.1. the additional product and
                    work concern extra panels and a larger inverter. In this case, the Additional
                    Charge will be determined by the difference in pricing between the larger
                    System and the originally selected System; </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '111.85pt', textIndent: '-43.2pt', lineHeight: '90%' }}>2.8.2. the additional
                    product, work or custom work is described in the “Additional Costs” document
                    and was not already added as a Pro<span style={{ fontSize: '26.0pt', lineHeight: '90%', fontFamily: '"Times New Roman",serif', color: '#BFBFBF' }}>.</span><span style={{ fontSize: '26.0pt', lineHeight: '90%', fontFamily: '"Times New Roman",serif', color: 'black' }}> </span>visional Charge; </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '111.35pt', textIndent: '-42.7pt' }}>2.8.3. the Customer was notified
                    about the additional product, work or custom work before Completion and the
                    Customer agrees to the Additional Charge in writing on or prior to Completion; </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '111.35pt', textIndent: '-42.7pt' }}>2.8.4. in order to ensure timely
                    supplies, the Supplier is forced to upgrade the nominal output of a panel to a
                    higher output, an extra charge per panel is due. The extra charge for
                    additional Wattpeak (Wp), is equal to the Wp price already charged. For
                    example, if the Wp price for the panel was 30p, an upgrade with 10 Wp would
                    result in an additional charge of £3 per panel. </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '68.35pt' }}>2.9. This Agreement will terminate at no cost to the
                    Customer forthwith if the System "does not fit". In this event, the
                    Deposit shall be reimbursed promptly, and the Customer will not have to pay the
                    Supplier any further amount. "Does not fit" means that the Survey
                    Report identifies any of the following circumstances: </p>
                  <p className="MsoNormal" style={{ marginLeft: '0cm', textAlign: 'left', textIndent: '0cm' }}><span style={{ fontSize: '11.0pt', lineHeight: '112%', fontFamily: '"Calibri",sans-serif', color: 'black' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>2.9.1. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; the
                    System set out in the Personal Recommendation does not physically fit on the roof;
                  </p>
                  <p className="MsoNormal" style={{ marginLeft: '0cm', textAlign: 'left', textIndent: '0cm' }}><span style={{ fontSize: '11.0pt', lineHeight: '112%', fontFamily: '"Calibri",sans-serif', color: 'black' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>2.9.2. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; installation
                    of the System would be unwise because it poses a risk for the roof; </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '110.65pt', textIndent: '-42.7pt' }}>2.9.3. too much shadow precludes a
                    decent yield, a decent yield being estimated at a minimum of 70% of the
                    System's Wattpeak; </p>
                  <p className="MsoNormal" style={{ marginLeft: '0cm', textAlign: 'left', textIndent: '0cm' }}><span style={{ fontSize: '11.0pt', lineHeight: '112%', fontFamily: '"Calibri",sans-serif', color: 'black' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>2.9.4. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; the
                    roof's orientation is unfavorable (more to the north rather than south, east or
                    west); </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '110.45pt', textIndent: '-42.5pt' }}>2.9.5. the Supplier is forced to add
                    Additional Charges to the Contract in order to be able to deliver the System,
                    the Additional Charges have been set out in a Revised Contract and </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '110.4pt', textIndent: '0cm' }}>the Customer has indicated in writing that
                    they do not accept the Additional Charges or has not accepted the Revised
                    Contract within the period specified in the Revised Contract. </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '67.25pt' }}><span style={{ color: 'black' }}>2.10. </span>The Customer is
                    entitled to a statutory cancellation period (the "Cancellation
                    Period", in common<span style={{ color: '#161616' }}> </span>language also
                    referred to as "cool-down period" or "cooling off period")
                    of 14 calendar days after the date that all of the components of the System
                    have been delivered to the Customer's Property. During this Cancellation Period
                    (but not after), the Customer may terminate this Agreement by notice in writing
                    (including e-mail) to the Supplier at no cost (unless clause 2.11, 2.12 and
                    2.13 applies) and without stating a reason. If the Customer terminates this
                    Agreement during the Cancellation Period, the Supplier will reimburse the
                    Acceptance Deposit and Installation Deposit in full within 14 days of
                    termination (unless clause 2.11, 2.12 or 2.13 applies).<span style={{ color: 'black' }}>
                    </span></p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '12.95pt', marginBottom: '.65pt', marginLeft: '67.25pt' }}><span style={{ color: 'black' }}>2.11. </span>The Supplier
                    may provide the Survey before and/or during the Cancellation Period with the
                    prior written permission of the Customer. The Customer's right to terminate
                    will remain in place even if the Supplier has provided the Survey before or
                    during the Cancellation Period. However, if the Customer terminates this Agreement
                    after the Supplier carries out the Survey, the Customer will be required to pay
                    for the cost of carrying out the Survey. For the avoidance of doubt, the
                    Customer will be provided with the Survey Report following the termination of
                    this Agreement.<span style={{ color: 'black' }}> </span></p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '12.55pt', marginBottom: '.65pt', marginLeft: '67.25pt' }}><span style={{ color: 'black' }}>2.12. </span>The Supplier
                    will not begin the Works during the Cancellation Period except with the prior
                    written (including e-mail) permission of the Customer. The Customer's right to
                    terminate will remain in place even if the Supplier has begun the Works during
                    the Cancellation Period, however, if the Works have commenced and the Customer
                    terminates during the Cancellation Period, the Customer will be required to pay
                    for the cost of the Works already undertaken and the portion of the System
                    already installed, and any reasonable costs associated with making good any
                    damage to the Customer's Property but the Supplier shall not be required to
                    remove any part of the System that has been installed.<span style={{ color: 'black' }}>
                    </span></p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '12.9pt', marginBottom: '.65pt', marginLeft: '67.25pt' }}><span style={{ color: 'black' }}>2.13. </span>Once
                    installation of the System is complete (even if this is within the Cancellation
                    Period and following the Customer's written permission to begin the Works
                    during the Cancellation Period) the Deposit becomes non-refundable.<span style={{ color: 'black' }}> </span></p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '67.25pt' }}><span style={{ color: 'black' }}>2.14. </span>Without prejudice
                    to clause 2.13 above, the Acceptance Deposit becomes non-refundable for any
                    costs incurred if:<span style={{ color: 'black' }}> </span></p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '114.25pt', textIndent: '-42.6pt' }}>2.14.1. &nbsp;&nbsp; the Customer agreed to a
                    Revised Contract, the Supplier carries out the Survey and the Customer later
                    cancels this Agreement; and </p>
                  <p className="MsoNormal" align="center" style={{ marginTop: '0cm', marginRight: '6.7pt', marginBottom: '1.3pt', marginLeft: '59.75pt', textAlign: 'center', textIndent: '-.5pt', lineHeight: '107%' }}>2.14.2. &nbsp;&nbsp;&nbsp;&nbsp; after carrying out the Survey, the Supplier
                    cancels this Agreement as a result of many failed and logged attempts to
                    contact the Customer and carry out the Works. </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '1.45pt', marginLeft: '37.9pt', textAlign: 'left', textIndent: '0cm', lineHeight: '105%' }}><span style={{ color: 'black' }}>2.15. The cost for an onsite survey
                    is set at £150. </span></p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '38.65pt', textIndent: '0cm' }}>2.16. The Customer may terminate this
                    Agreement due to: </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '114.25pt', textIndent: '-42.6pt' }}>2.16.1. &nbsp;&nbsp; a proven rejection of any
                    required planning consent, license, permit, or other permission required in
                    order to complete the Works in accordance with any laws; or </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '114.25pt', textIndent: '-42.6pt' }}>2.16.2. any external funding or other
                    conditions precedent set out in this Agreement, up to 5 days before the date on
                    which the Works are planned to commence. </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '2.65pt', marginLeft: '67.25pt' }}>2.17. The Customer may exercise the right to cancel by
                    using the model cancellation form at the end of this Agreement. </p>
                  <p className="MsoNormal" align="center" style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '105.5pt', textAlign: 'center', textIndent: '0cm', lineHeight: '107%' }}><i><span style={{ color: '#B8B8B8' }}>I'</span></i><i><span style={{ color: 'black' }}> </span></i></p>
                  <p className="MsoNormal" style={{ margin: '0cm', textAlign: 'left', textIndent: '0cm', lineHeight: '107%' }}><i><span style={{ color: 'black' }}>&nbsp;</span></i></p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '1.8pt', marginLeft: '72.0pt', textAlign: 'left', textIndent: '-29.4pt', lineHeight: '107%' }}><b><span style={{ lineHeight: '107%' }}>3.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span></span></b><b>CUSTOMER'S RESPONSIBILITIES </b></p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '1.3pt', marginLeft: '68.8pt', textAlign: 'left', textIndent: '0cm', lineHeight: '107%' }}>&nbsp;</p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '72.1pt', textIndent: '-28.55pt' }}><span style={{ lineHeight: '112%' }}>3.1.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>The
                    Customer warrants that they are entitled to have the System Delivered at the
                    Customer's Property and that they have obtained all consents that may be
                    required from any third party having an interest in the Customer's Property
                    such as a landlord, tenant or mortgage company. </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '72.1pt', textIndent: '-28.55pt' }}><span style={{ lineHeight: '112%' }}>3.2.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>The
                    space required for the execution of the Work shall be cleared by the Customer
                    before the start of the Works and the Customer will give the Supplier
                    sufficient rights to access the parts of the Customer's Property where the Work
                    will be carried out. The space to work and reasonable quantities of electricity
                    and water shall be made available free of charge to the Supplier's installers. </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '72.1pt', textIndent: '-28.55pt' }}><span style={{ lineHeight: '112%' }}>3.3.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>If
                    necessary, the Supplier shall install a ladder, scaffold or hoisting platform
                    for the works. </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '72.1pt', textIndent: '-28.55pt' }}><span style={{ lineHeight: '112%' }}>3.4.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>The
                    Supplier will indicate what permits, licenses, consents or other permissions
                    are necessary in </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '68.8pt', textIndent: '0cm' }}>order to complete the Delivery, and the
                    Customer and the Supplier will agree which party will be responsible for
                    obtaining such permits, licenses, consents or other permissions. The Customer
                    shall be responsible for the costs of obtaining any permits, licenses, consents
                    or other permissions necessary for the Works. </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '72.1pt', textIndent: '-28.55pt' }}><span style={{ lineHeight: '112%' }}>3.5.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>The
                    Customer warrants that, to the best of its knowledge and belief, no asbestos is
                    present in the Customer's Property. </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.1pt', marginLeft: '72.1pt', textIndent: '-28.55pt', lineHeight: '117%' }}><span style={{ lineHeight: '117%' }}>3.6.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span></span>The electrical system in the Customer's Property must comply with
                    all current regulations, be safe and fit for the purpose of the Works. If works
                    have to be carried out to the electrical system in the Customer's Property to
                    ensure compliance with regulations, an additional charge may be invoiced to the
                    Customer provided that such additional charge has been stated in the Survey
                    Report. </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '72.1pt', textIndent: '-28.55pt' }}><span style={{ lineHeight: '112%' }}>3.7.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>The
                    Customer agrees that the inverter will be installed within the Customer's
                    Property and is aware of the fact that the inverter may make a light humming
                    sound. In order to ensure that it has sufficient ventilation, the Customer must
                    not cover the inverter. </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '72.1pt', textIndent: '-28.55pt' }}><span style={{ lineHeight: '112%' }}>3.8.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>The
                    System shall be delivered with a monitoring system in full working order. The
                    Customer shall ensure that a suitable WiFi network is available and accessible
                    (passwords available). If this is not the case, then the Supplier may leave
                    behind an instruction manual and/or remote telephone support for the customer
                    and will have no responsibility for completing the connection with the
                    monitoring system. </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '72.1pt', textIndent: '-28.55pt' }}><span style={{ lineHeight: '112%' }}>3.9.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>If
                    the Supplier is asked to solve a problem by the Customer, after the initial
                    delivery of the System in full working order, including monitoring, then the
                    Supplier may invoice a reasonable additional charge proportionate to the work completed
                    by the Supplier for this problem if the problem is caused by components which
                    have not been supplied by the Supplier. </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '72.1pt', textIndent: '-28.55pt' }}><span style={{ lineHeight: '112%' }}>3.10.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp; </span></span>During and
                    immediately after the Works the Customer must be present to sign the Supplier's
                    "sign off document". The Customer's signature on the sign off
                    document indicates that the Customer is satisfied that the Works have been
                    Completed and that the System is working, but this does not affect<span style={{ color: '#2A2A2A' }}> </span>the Customer's rights under this Agreement. </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '72.1pt', textIndent: '-28.55pt' }}><span style={{ lineHeight: '112%' }}>3.11.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp; </span></span>
                    On discovering a failure of or defect in the System, the Customer must notify
                    the Supplier as soon as possible. </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '72.1pt', textIndent: '-28.55pt' }}><span style={{ lineHeight: '112%' }}>3.12.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp; </span></span>If the Customer
                    suspects a fault in the solar panels, and calls the Supplier out to
                    investigate, the Supplier will, should there be a defect in a part not covered
                    by the warranty, indicate to the Customer that the defect is one not covered by
                    the warranty, and that should the Supplier take any further action this may
                    result in the costs of resolving the defect being borne by the Customer. </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '72.1pt', textIndent: '-28.55pt' }}><span style={{ lineHeight: '112%' }}>3.13.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp; </span></span>The Customer is
                    responsible for having the solar panels cleaned and for the control and removal
                    of any bird's nests or accumulated debris under the solar panels. Failure to do
                    this could impact on the performance of the System. </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '3.35pt', marginLeft: '72.1pt', textIndent: '-28.55pt' }}><span style={{ lineHeight: '112%' }}>3.14.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp; </span></span>You agree to
                    provide the following for use by the Supplier' s installers free of any charge:
                  </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '111.55pt', textIndent: '-18.6pt' }}><span style={{ lineHeight: '112%', color: '#2A2A2A' }}>•<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span></span>water, washing facilities and toilets; </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '111.55pt', textIndent: '-18.6pt' }}><span style={{ lineHeight: '112%', color: '#2A2A2A' }}>•<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span></span>electricity supply; </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '111.55pt', textIndent: '-18.6pt' }}><span style={{ lineHeight: '112%', color: '#2A2A2A' }}>•<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span></span>adequate storage space during the Works; </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '1.7pt', marginLeft: '111.55pt', textIndent: '-18.6pt' }}><span style={{ lineHeight: '112%', color: '#2A2A2A' }}>•<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span></span>safe and easy access to your property from the public highway; </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '111.55pt', textIndent: '-18.6pt' }}><span style={{ lineHeight: '112%', color: '#2A2A2A' }}>•<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span></span>easy access to the location within the property where the
                    installation is to take place by removing all belongings; </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '1.45pt', marginLeft: '111.55pt', textIndent: '-18.6pt', lineHeight: '105%' }}><span style={{ lineHeight: '105%', color: '#2A2A2A' }}>•<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span></span><span style={{ color: 'black' }}>free parking or where there is no or
                    insufficient free parking available on the property the costs of parking one
                    vehicle for a maximum of two days </span></p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '1.3pt', marginLeft: '111.6pt', textAlign: 'left', textIndent: '0cm', lineHeight: '107%' }}>&nbsp;</p>
                  <p className="MsoNormal" style={{ margin: '0cm', textAlign: 'left', textIndent: '0cm', lineHeight: '107%' }}><span style={{ color: 'black' }}>&nbsp;</span></p>
                  <p className="MsoNormal" style={{ margin: '0cm', textAlign: 'left', textIndent: '0cm', lineHeight: '107%' }}><span style={{ color: 'black' }}>&nbsp;</span></p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '72.0pt', textAlign: 'left', textIndent: '-29.4pt', lineHeight: '107%' }}><b><span style={{ lineHeight: '107%' }}>4.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span></span></b><b><span style={{ color: '#131313' }}>PAYMENTS &nbsp;&nbsp;&nbsp;&nbsp; </span></b><span style={{ color: '#CACACA' }}></span><span style={{ color: '#2A2A2A' }}> </span></p>
                  <p className="MsoNormal" style={{ margin: '0cm', textAlign: 'left', textIndent: '0cm', lineHeight: '107%' }}><span style={{ color: 'black' }}>&nbsp;</span></p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '72.1pt', textIndent: '-28.55pt' }}><span style={{ lineHeight: '112%' }}>4.1.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>The
                    Acceptance Deposit will be retained in a 3rd party account. </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.1pt', marginLeft: '72.1pt', textIndent: '-28.55pt', lineHeight: '117%' }}><span style={{ lineHeight: '117%' }}>4.2.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span></span>The Supplier may invoice the Customer for no more than 25% of the
                    total price minus the Acceptance Deposit, prior to installation on confirmation
                    of an installation appointment. This payment will be taken as a protected
                    Installation Deposit Payment. </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '114.6pt', textIndent: '-42.95pt' }}><span style={{ lineHeight: '112%' }}>4.2.1.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span></span>The Installation Deposit Payment must be registered and protected
                    through a deposit protection scheme, and the Customer must receive a Deposit
                    Protection Certificate. </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.6pt', marginLeft: '114.6pt', textIndent: '-42.95pt', lineHeight: '107%' }}><span style={{ lineHeight: '107%' }}>4.2.2.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span></span>The full Installation Deposit Payment will be refundable to the
                    Customer if the installation </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '114.25pt', textIndent: '0cm' }}>appointment is cancelled, and no works
                    have started&nbsp; </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.1pt', marginLeft: '114.6pt', textIndent: '-42.95pt', lineHeight: '117%' }}><span style={{ lineHeight: '117%' }}>4.2.3.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span></span>The processing and handling of the Installation Deposit Payment
                    must adhere to the guidelines outlined in the Supplier’s chosen deposit
                    protection scheme terms and conditions) </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '72.1pt', textIndent: '-28.55pt' }}><span style={{ lineHeight: '112%' }}>4.3.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>Unless
                    otherwise agreed the Customer shall pay the balance of the price (total price
                    minus the Acceptance Deposit and if applicable minus the protected Installation
                    Deposit Payment outlined in 4.2) within 10 working days of the receipt of the
                    Supplier’s invoice. </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '72.1pt', textIndent: '-28.55pt' }}><span style={{ lineHeight: '112%' }}>4.4.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>If
                    the Customer fails to make payment on the due date for payment the Supplier
                    will be entitled to charge interest at the rate of 3% above the Bank of England
                    Base Rate from the due date for payment until payment is received in cleared
                    funds, both before and after any judgment. </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '72.1pt', textIndent: '-28.55pt' }}><span style={{ lineHeight: '112%' }}>4.5.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>In
                    the event of any alleged minor defect with the goods or installation, the
                    Customer shall not be entitled to withhold more than a proportionate amount of
                    the sum due. If Customer does withhold any amount after the due date because of
                    any alleged minor defect, then Customer must give us as much notice as possible
                    and state the reasons of withholding the payment. </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '72.1pt', textIndent: '-28.55pt' }}><span style={{ lineHeight: '112%' }}>4.6.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>The
                    warranties only become effective after payment in full of all invoices issued
                    by the Supplier. </p>
                  <p className="MsoNormal" style={{ margin: '0cm', textAlign: 'left', textIndent: '0cm', lineHeight: '107%' }}><span style={{ color: 'black' }}>&nbsp;</span></p>
                  <p className="MsoNormal" style={{ margin: '0cm', textAlign: 'left', textIndent: '0cm', lineHeight: '107%' }}><span style={{ color: 'black' }}>&nbsp;</span></p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '72.0pt', textAlign: 'left', textIndent: '-29.4pt', lineHeight: '107%' }}><b><span style={{ lineHeight: '107%' }}>5.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span></span></b><b><span style={{ color: '#131313' }}>RISK AND TRANSFER OF OWNERSHIP
                  </span></b></p>
                  <p className="MsoNormal" style={{ margin: '0cm', textAlign: 'left', textIndent: '0cm', lineHeight: '107%' }}><b><span style={{ color: 'black' }}>&nbsp;</span></b></p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '72.1pt', textIndent: '-28.55pt' }}><span style={{ lineHeight: '112%' }}>5.1.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>Title
                    to the System and all parts of it remains with the Supplier until the Customer
                    has paid all amounts due from the Customer to the Supplier.&nbsp; </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '114.6pt', textIndent: '-42.95pt' }}><span style={{ lineHeight: '112%' }}>5.1.1.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span></span>In the event that advance payment has been taken in the form of
                    the Installation Deposit, and that payment has been used to purchase goods to
                    facilitate Delivery, the legal title for those goods or a proportion of them
                    that have been paid for will pass to the Customer.&nbsp;&nbsp; </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '72.1pt', textIndent: '-28.55pt' }}><span style={{ lineHeight: '112%' }}>5.2.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>Transfer
                    of responsibility for and risk in the System takes place upon Completion. After
                    this time, it is the Customer’s responsibility to take proper care of the
                    System, including insuring the System as part your buildings insurance. </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '72.1pt', textIndent: '-28.55pt' }}><span style={{ lineHeight: '112%' }}>5.3.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>Any
                    defects or non-compliance with this Agreement on the day of Completion must be
                    clearly set out in the Supplier’s sign off form. </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '72.1pt', textIndent: '-28.55pt' }}><span style={{ lineHeight: '112%' }}>5.4.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>Damage
                    and remedying damage caused by the Supplier during the Works is always the
                    Supplier’s responsibility and shall be treated as a complaint by the Customer.
                    The Customer must enable the Supplier to remedy the complaint. The Supplier
                    will attempt to resolve any damage caused during the carrying out of the Works
                    as quickly as possible, and if reasonably, possible within 5 days of
                    Completion.<span style={{ color: '#1F1F1F' }}> </span></p>
                  <p className="MsoNormal" style={{ margin: '0cm', textAlign: 'left', textIndent: '0cm', lineHeight: '107%' }}><span style={{ color: 'black' }}>&nbsp;</span></p>
                  <p className="MsoNormal" style={{ margin: '0cm', textAlign: 'left', textIndent: '0cm', lineHeight: '107%' }}><span style={{ color: 'black' }}>&nbsp;</span></p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '72.0pt', textAlign: 'left', textIndent: '-29.4pt', lineHeight: '107%' }}><b><span style={{ lineHeight: '107%' }}>6.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span></span></b><b><span style={{ color: '#1F1F1F' }}>COMPLAINTS </span></b></p>
                  <p className="MsoNormal" style={{ margin: '0cm', textAlign: 'left', textIndent: '0cm', lineHeight: '107%' }}><b><span style={{ color: 'black' }}>&nbsp;</span></b></p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '72.1pt', textIndent: '-28.55pt' }}><span style={{ lineHeight: '112%' }}>6.1.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>Complaints
                    must be notified to the Supplier, by phone, e-mail or letter. The Supplier will
                    acknowledge receipt of the complaint from the Customer and the content of the
                    complaint within a reasonable period. </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.6pt', marginLeft: '114.6pt', textIndent: '-42.95pt', lineHeight: '107%' }}><span style={{ lineHeight: '107%' }}>6.1.1.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span></span>The Supplier undertakes to respond within 2 working days
                    following receipt of a complaint. </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '114.6pt', textIndent: '-42.95pt' }}><span style={{ lineHeight: '112%' }}>6.1.2.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span></span>The Supplier undertakes to resolve the complaint within 10
                    working days following receipt, if reasonably possible. If it is not possible
                    to resolve the complaint in this time, the Supplier will agree with the Customer,
                    acting reasonably, the most appropriate time in which to resolve the complaint.
                  </p>
                  <p className="MsoNormal">6.2&nbsp; If the Customer’s complaint is not dealt with to the
                    Customer’s satisfaction, the Customer can use RECC’s Dispute Resolution
                    Process. The Supplier must agree to follow this process. This can include the
                    option of using RECC’s Independent Arbitration Service in order to obtain a
                    legally binding resolution to the dispute as an alternative to court action.
                    RECC is certified through the Chartered Trading Standards Institute as an
                    Alternative Dispute Resolution Provider. You can find further information on
                    the RECC website https://www.recc.org.uk/consumers/how-to-complain.&nbsp; </p>
                  <p className="MsoNormal">6.3&nbsp; If the Customer’s complaint is not dealt with to the
                    Customer’s satisfaction, the Customer can use HIES’s Alternative Dispute
                    Resolution Process. The Supplier must agree to follow this process. This can
                    include the option of using the ombudsman, in order to obtain a resolution to
                    the dispute as an alternative to court action. HIES is certified through the
                    Chartered Trading Standards Institute as an Alternative Dispute Resolution
                    Provider. You can find further information on HIES website <a href="https://www.hiesscheme.org.uk/contact/">https://www.hiesscheme.org.uk/contact/</a><a href="https://www.hiesscheme.org.uk/contact/">.</a> </p>
                  <p className="MsoNormal" style={{ margin: '0cm', textAlign: 'left', textIndent: '0cm', lineHeight: '107%' }}>&nbsp;</p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '71.9pt', textAlign: 'left', textIndent: '0cm', lineHeight: '107%' }}><b><span style={{ color: '#2F2F2F' }}>&nbsp;</span></b></p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '72.0pt', textAlign: 'left', textIndent: '-29.4pt', lineHeight: '107%' }}><b><span style={{ lineHeight: '107%' }}>7.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span></span></b><b><span style={{ color: '#1F1F1F' }}>DELIVERIES</span></b><b><span style={{ color: '#2F2F2F' }}> </span></b></p>
                  <p className="MsoNormal" style={{ margin: '0cm', textAlign: 'left', textIndent: '0cm', lineHeight: '107%' }}><b><span style={{ color: 'black' }}>&nbsp;</span></b></p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '72.1pt', textIndent: '-28.55pt' }}><span style={{ lineHeight: '112%' }}>7.1.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>Customer
                    accepts the lengthened period for delivery in this group purchase scheme. The
                    latest date for Completion is mentioned on the scheme website and is usually 6
                    to 7 months after the </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '2.2pt', marginLeft: '71.65pt', textIndent: '0cm' }}>Auction took place. A delay in
                    commencement of the Work and/or Completion does not entitle the </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '71.4pt', textIndent: '-71.4pt', lineHeight: '152%' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Customer
                    to compensation, or the right to terminate this Agreement unless any of the
                    following apply:  &nbsp;</p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '2.4pt', marginLeft: '114.6pt', textIndent: '-42.95pt' }}><span style={{ lineHeight: '112%' }}>7.1.1.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span></span>the Supplier has refused to deliver the System; or </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '114.6pt', textIndent: '-42.95pt', lineHeight: '135%' }}><span style={{ lineHeight: '135%' }}>7.1.2.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span></span>the Customer expressly informed the<sup><span style={{ fontSize: '8.5pt', lineHeight: '135%', fontFamily: '"Times New Roman",serif', color: '#BCBCBC' }}>1</span></sup><sup><span style={{ fontSize: '8.5pt', lineHeight: '135%', fontFamily: '"Times New Roman",serif', color: 'black' }}> &nbsp;&nbsp;&nbsp; </span></sup>&nbsp;Supplier that delivery within the delivery
                    times provided was essential. </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '72.1pt', textIndent: '-28.55pt' }}><span style={{ lineHeight: '112%' }}>7.2.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>The
                    Supplier shall use reasonable endeavors to achieve Completion, calculated from
                    the start of the Works, in two consecutive working days. </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '72.1pt', textIndent: '-28.55pt' }}><span style={{ lineHeight: '112%' }}>7.3.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>The
                    Supplier will inform the Customer if there is any significant change to the
                    start date or timetable of the Works, and if this change adversely affects the
                    Customer, the Supplier will agree a further start date for the Works that is
                    agreed by both the Supplier and the Customer acting reasonably. </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '72.1pt', textIndent: '-28.55pt' }}><span style={{ lineHeight: '112%' }}>7.4.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>If
                    the Supplier proposes a date which is more than 30 days after the original
                    latest date for Completion, the Customer may cancel this Agreement and be
                    reimbursed fully any amounts paid to the Supplier. </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '72.1pt', textIndent: '-28.55pt' }}><span style={{ lineHeight: '112%' }}>7.5.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>The
                    Customer should note that weather conditions that prevent Delivery or are
                    deemed unsafe may lead to the postponement of the Delivery at the last minute
                    and that the Customer will not be entitled to terminate this&nbsp;&nbsp; Agreement or to
                    receive any compensation in these circumstances. </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '72.1pt', textIndent: '-28.55pt' }}><span style={{ lineHeight: '112%' }}>7.6.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>The
                    Customer has legal rights in relation to the System including being of
                    satisfactory quality, fit for purpose and as described as by Supplier. Advice
                    about your legal rights is available from your local Citizen’ s Advice Bureau
                    or Trading Standards office. Nothing in these terms will affect these legal
                    rights. </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '72.1pt', textIndent: '-28.55pt' }}><span style={{ lineHeight: '112%' }}>7.7.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>In
                    the case of severe delays to the delivery of goods then you may be offered
                    different products of equivalent specification, value and quality, so long as
                    they are compliant with all criteria set in the scheme. You can either accept
                    that offer, wait for the products you ordered or choose to cancel the contract.
                    <span style={{ color: 'black' }}>&nbsp;</span></p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '72.1pt', textIndent: '-28.55pt' }}><span style={{ lineHeight: '112%' }}>7.8.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>Notwithstanding
                    anything to the contrary in this clause 7, the Supplier is entitled to extend
                    the scheme’s period for Completion of all Works, if the number of Customers in
                    the scheme is greater than anticipated. The scheme’s organiser will inform all
                    Customers at latest within 10 working-days after the scheme closes for
                    Customers to accept the Personal Recommendation. </p>
                  <p className="MsoNormal" style={{ margin: '0cm', textAlign: 'left', textIndent: '0cm', lineHeight: '107%' }}><span style={{ color: 'black' }}>&nbsp;</span></p>
                  <p className="MsoNormal" style={{ margin: '0cm', textAlign: 'left', textIndent: '0cm', lineHeight: '107%' }}><span style={{ color: 'black' }}>&nbsp;</span></p>
                  <h1 style={{ marginLeft: '0cm', textIndent: '0cm' }}><span style={{ fontSize: '11.0pt', lineHeight: '107%', fontFamily: '"Calibri",sans-serif', color: 'black', fontWeight: 'normal' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span style={{ color: 'black' }}>8. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>EVENTS OUTSIDE OUR CONTROL<span style={{ color: 'black' }}> </span></h1>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '.7pt', marginLeft: '0cm', textAlign: 'left', textIndent: '0cm', lineHeight: '107%' }}><b><span style={{ color: 'black' }}>&nbsp;</span></b></p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '72.15pt' }}>8.1. The Supplier will be relieved of any liability for
                    any delay or failure to perform its obligations under this Agreement due to
                    circumstances beyond its reasonable control (including but not limited to:
                    adverse weather, accidents, war, delays in transport, fire, theft, acts of
                    God). The Supplier is required to take reasonable steps to prevent or minimise
                    delay.&nbsp; </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '72.15pt' }}>8.2. The Supplier reserves the right to suspend the Works
                    and/or to postpone the date for Completion for so long as such circumstances
                    last. The Supplier will give the Customer notice of suspension and communicate
                    an expectation for an adjusted timeline. The Customer is entitled to terminate
                    in case the circumstances continue beyond the extended timeline that has been
                    communicated.&nbsp; </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '72.15pt' }}>8.3. If, as a result of the circumstance described in 8.2.
                    the Customer terminates the contract, the Customer will still be required to
                    pay for the cost of the Works already undertaken, the portion of the System
                    already installed and reasonable costs associated with making good any damage
                    to the Customer’s Property caused by the scope of the work e.g. drilling to
                    tiles for PV mounting brackets. </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '.6pt', marginLeft: '71.65pt', textAlign: 'left', textIndent: '0cm', lineHeight: '107%' }}>&nbsp;</p>
                  <h1 style={{ marginLeft: '0cm', textIndent: '0cm' }}><span style={{ fontSize: '11.0pt', lineHeight: '107%', fontFamily: '"Calibri",sans-serif', color: 'black', fontWeight: 'normal' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span style={{ color: '#1C1C1C' }}>9. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>WARRANTIES<span style={{ color: '#1C1C1C', fontWeight: 'normal' }}> </span></h1>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '73.6pt', textAlign: 'left', textIndent: '0cm', lineHeight: '107%' }}>&nbsp;</p>
                  <p className="MsoNormal" style={{ marginLeft: '0cm', textAlign: 'left', textIndent: '0cm' }}><span style={{ fontSize: '11.0pt', lineHeight: '112%', fontFamily: '"Calibri",sans-serif', color: 'black' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>9.1. &nbsp;&nbsp;&nbsp;&nbsp; The
                    following warranties apply to the System: </p>
                  <p className="MsoNormal" style={{ marginLeft: '0cm', textAlign: 'left', textIndent: '0cm' }}><span style={{ fontSize: '11.0pt', lineHeight: '112%', fontFamily: '"Calibri",sans-serif', color: 'black' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>9.1.1.
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; String inverter(s): ten-year product warranty given by the
                    manufacturer; </p>
                  <p className="MsoNormal" style={{ marginLeft: '0cm', textAlign: 'left', textIndent: '0cm' }}><span style={{ fontSize: '11.0pt', lineHeight: '112%', fontFamily: '"Calibri",sans-serif', color: 'black' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>9.1.2.
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Solar panels: twelve-year product warranty given by the manufacturer; </p>
                  <p className="MsoNormal" style={{ marginLeft: '0cm', textAlign: 'left', textIndent: '0cm' }}><span style={{ fontSize: '11.0pt', lineHeight: '112%', fontFamily: '"Calibri",sans-serif', color: 'black' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>9.1.3.
                    &nbsp;&nbsp;&nbsp;&nbsp; Battery storage: ten-year product warranty given by the manufacturer; </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '114.25pt', textIndent: '-42.6pt' }}>9.1.4. Capacity of the solar panels:
                    90% of the capacity of the solar panels for the first ten years and 80% of the
                    capacity of the solar panels for a period of 15 years, following the first ten
                    years, given by the manufacturer; </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '114.25pt', textIndent: '-42.6pt' }}>9.1.5. Workmanship: The Supplier
                    warrants that for a period of 10 years from the date of Completion the Works
                    (excluding the inverter and the solar panels) will be free from defects in
                    design, workmanship and materials. </p>
                  <p className="MsoNormal" style={{ marginLeft: '0cm', textAlign: 'left', textIndent: '0cm' }}><span style={{ fontSize: '11.0pt', lineHeight: '112%', fontFamily: '"Calibri",sans-serif', color: 'black' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>9.1.6.
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Insurance Backed Guarantee for Workmanship for 10 years: The
                    Supplier will provide the </p>
                  <p className="MsoNormal" align="right" style={{ marginTop: '0cm', marginRight: '9.0pt', marginBottom: '.6pt', marginLeft: '.5pt', textAlign: 'right', textIndent: '-.5pt', lineHeight: '107%' }}>Customer with an insurance that will cover the liability for
                    workmanship in case the </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '114.25pt', textIndent: '0cm' }}>Supplier falls into receivership,
                    administration or bankruptcy. The Supplier will provide the Customer with
                    details of the insurance scheme and the Customer will receive a policy.
                    directly from the provider after transfer of ownership of the system. The
                    Customer may be required to respond directly to the insurer in order to receive
                    a personalised policy. </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '114.25pt', textIndent: '-42.6pt' }}>9.1.7. Warranties on other products
                    and/or parts of the System, including power diverters, will be specified in
                    product information sheets provided with the System. </p>
                  <p className="MsoNormal" style={{ marginLeft: '0cm', textAlign: 'left', textIndent: '0cm' }}><span style={{ fontSize: '11.0pt', lineHeight: '112%', fontFamily: '"Calibri",sans-serif', color: 'black' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>9.2. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The
                    warranties are assignable to a purchaser of the Customer's interest in the
                    Customer's Property </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '72.15pt' }}>9.3. The Supplier shall not be liable in respect of any
                    defect, non-compliance or damage which arose as a<span style={{ color: '#181818' }}> </span>result
                    of: </p>
                  <p className="MsoNormal" style={{ marginLeft: '0cm', textAlign: 'left', textIndent: '0cm' }}><span style={{ fontSize: '11.0pt', lineHeight: '112%', fontFamily: '"Calibri",sans-serif', color: 'black' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>9.3.1.
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; neglect or poor care by the Customer; </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '116.4pt', textIndent: '-43.2pt' }}>9.3.2. &nbsp;&nbsp;&nbsp;&nbsp; repairs, maintenance or
                    changes to the System by a third party not authorised by the Supplier. </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '72.15pt' }}>9.4. Any liability is expressly excluded after the
                    warranty period ends. These warranty conditions are in addition to and do not
                    affect the Customer's legal rights. </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '43.55pt', textIndent: '0cm' }}>9.5. In the event of a breach of the warranty
                    in clause 9.1.4 the Supplier will: </p>
                  <p className="MsoNormal" style={{ marginLeft: '0cm', textAlign: 'left', textIndent: '0cm' }}><span style={{ fontSize: '11.0pt', lineHeight: '112%', fontFamily: '"Calibri",sans-serif', color: 'black' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>9.5.1.
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; use reasonable endeavours to correct the fault at the Customer's
                    Property; </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '2.7pt', marginLeft: '116.5pt', textIndent: '-43.3pt' }}>9.5.2. &nbsp;&nbsp;&nbsp;&nbsp; provide replacement
                    equipment or extra equipment to restore the System to its original condition;&nbsp; </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '2.7pt', marginLeft: '116.5pt', textIndent: '-43.3pt' }}>9.5.3. &nbsp;&nbsp;&nbsp;&nbsp; or refund a fair
                    proportion of the price paid by the Customer to the Customer based on the part
                    of the System that is faulty. </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '2.7pt', marginLeft: '72.15pt' }}>9.6. &nbsp;&nbsp; Every intervention which is requested by the
                    Customer and which does not relate to a defect in the Works will be billed
                    (hours worked and travel). </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '3.5pt', marginLeft: '0cm', textAlign: 'left', textIndent: '0cm' }}><span style={{ fontSize: '11.0pt', lineHeight: '112%', fontFamily: '"Calibri",sans-serif', color: 'black' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>9.7. &nbsp;&nbsp;&nbsp;&nbsp; The Supplier does not limit
                    or exclude liability for: </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '121.55pt', textIndent: '-42.95pt' }}>9.7.1. &nbsp;&nbsp;&nbsp;&nbsp; death or personal injury
                    caused by the negligence of itself, its employees, agents or contractors; </p>
                  <p className="MsoNormal" style={{ marginLeft: '0cm', textAlign: 'left', textIndent: '0cm' }}><span style={{ fontSize: '11.0pt', lineHeight: '112%', fontFamily: '"Calibri",sans-serif', color: 'black' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>9.7.2.
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; fraud or fraudulent misrepresentation; </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '121.7pt', textIndent: '-43.45pt' }}>9.7.3. &nbsp;&nbsp;&nbsp;&nbsp; breach of legal rights in
                    relation to products or components supplied including the right to receive
                    products or components which are: </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '140.6pt', textIndent: '-18.25pt' }}><span style={{ lineHeight: '112%' }}>•<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>as
                    described and match information the Supplier provided to the Customer and any
                    sample or model seen or examined by the Customer; </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '140.6pt', textIndent: '-18.25pt' }}><span style={{ lineHeight: '112%' }}>•<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>of
                    satisfactory quality; </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '1.3pt', marginLeft: '140.6pt', textIndent: '-18.25pt', lineHeight: '107%' }}><span style={{ lineHeight: '107%' }}>•<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span></span>fit for any particular purpose made known to the Supplier; </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '140.6pt', textIndent: '-18.25pt' }}><span style={{ lineHeight: '112%' }}>•<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>supplied
                    with reasonable skill and care and, where installed by the Supplier, correctly
                    installed; </p>
                  <p className="MsoNormal" style={{ marginLeft: '0cm', textAlign: 'left', textIndent: '0cm' }}><span style={{ fontSize: '11.0pt', lineHeight: '112%', fontFamily: '"Calibri",sans-serif', color: 'black' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>9.7.4.
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; defective products or components under Consumer Protection Act 1987; and
                  </p>
                  <p className="MsoNormal" style={{ marginLeft: '0cm', textAlign: 'left', textIndent: '0cm' }}><span style={{ fontSize: '11.0pt', lineHeight: '112%', fontFamily: '"Calibri",sans-serif', color: 'black' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>9.7.5.
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; any other liability that cannot be excluded or limited as a matter of
                    law. </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '4.05pt', marginBottom: '.65pt', marginLeft: '77.95pt' }}><span style={{ position: 'relative', zIndex: -1895824384 }}><span style={{ left: '624px', position: 'absolute', top: '-943px', width: '147px', height: '1123px' }}></span></span>9.8.
                    The performance of solar PV systems is impossible to predict with certainty due
                    to the variability in the amount of solar radiation (sunlight) from location to
                    location and from year to year. This estimate is based upon the Government's
                    standard assessment procedure for energy rating of buildings (SAP) and is given
                    as guidance only. It should not be considered as a guarantee of performance. </p>
                  <p className="MsoNormal" style={{ margin: '0cm', textAlign: 'left', textIndent: '0cm', lineHeight: '107%' }}><span style={{ color: 'black' }}>&nbsp;</span></p>
                  <p className="MsoNormal" style={{ margin: '0cm', textAlign: 'left', textIndent: '0cm', lineHeight: '107%' }}><span style={{ color: 'black' }}>&nbsp;</span></p>
                  <h1 style={{ marginLeft: '0cm', textIndent: '0cm' }}><span style={{ fontSize: '11.0pt', lineHeight: '107%', fontFamily: '"Calibri",sans-serif', color: 'black', fontWeight: 'normal' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span style={{ color: '#161616' }}>10. &nbsp;&nbsp;&nbsp; PRIVACY </span></h1>
                  <p className="MsoNormal" style={{ margin: '0cm', textAlign: 'left', textIndent: '0cm', lineHeight: '107%' }}><b><span style={{ color: 'black' }}>&nbsp;</span></b></p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '77.35pt' }}>10.1. The Supplier shall collect, store and process all
                    Customer Personal Data in accordance with its Privacy Policy and applicable
                    Data Protection Law. </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '48.75pt', textIndent: '0cm' }}>10.2. The supplier shall: </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '.65pt', marginBottom: '.1pt', marginLeft: '120.65pt', textAlign: 'left', textIndent: '-43.45pt', lineHeight: '117%' }}>10.2 .1. at all times, ensure that it has in place
                    appropriate technical and organisational measures to guard against unauthorised
                    or unlawful processing of the Customer Personal Data and/or </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '5.0pt', marginLeft: '120.5pt', textIndent: '0cm' }}>accidental loss, destruction or damage to
                    the/,- same; </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '4.35pt', marginBottom: '.65pt', marginLeft: '120.5pt', textIndent: '-43.3pt' }}>10.2.2. not disclose any of the
                    Customer Personal Data to a third party unless necessary for the provision of
                    the System or the Works, pf as otherwise provided for in this Agreement or the
                    Privacy Policy; and </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '120.4pt', textIndent: '-43.2pt' }}>10.2.3. ensure the reliability and
                    training of all Supplier personnel who have access to the Customer Personal
                    Data. </p>
                  <p className="MsoNormal" style={{ margin: '0cm', textAlign: 'left', textIndent: '0cm', lineHeight: '107%' }}><span style={{ color: 'black' }}>&nbsp;</span></p>
                  <p className="MsoNormal" style={{ margin: '0cm', textAlign: 'left', textIndent: '0cm', lineHeight: '107%' }}><span style={{ color: 'black' }}>&nbsp;</span></p>
                  <h1 style={{ marginLeft: '0cm', textIndent: '0cm' }}><span style={{ fontSize: '11.0pt', lineHeight: '107%', fontFamily: '"Calibri",sans-serif', color: 'black', fontWeight: 'normal' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span style={{ color: '#161616' }}>11. &nbsp;&nbsp;&nbsp; APPLICABLE LAW AND DISPUTES </span></h1>
                  <p className="MsoNormal" style={{ margin: '0cm', textAlign: 'left', textIndent: '0cm', lineHeight: '107%' }}><b><span style={{ color: 'black' }}>&nbsp;</span></b></p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '77.0pt' }}>11.1. This Agreement is governed by English law and is
                    subject to the exclusive jurisdiction of the English Courts. </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '77.0pt' }}>11.2. In the event of a dispute the parties shall first use
                    reasonable endeavours to reach an amicable settlement, </p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '1.05pt', marginLeft: '77.3pt', textAlign: 'left', textIndent: '0cm', lineHeight: '107%' }}>&nbsp;</p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '77.3pt', textAlign: 'left', textIndent: '0cm', lineHeight: '107%' }}>&nbsp;</p>
                  <h1 style={{ marginLeft: '0cm', textIndent: '0cm' }}><span style={{ fontSize: '11.0pt', lineHeight: '107%', fontFamily: '"Calibri",sans-serif', color: 'black', fontWeight: 'normal' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span style={{ color: '#2B2B2B' }}>12. &nbsp;&nbsp;&nbsp; </span><span style={{ color: '#161616' }}>MISCELLANEOUS</span><span style={{ color: '#2B2B2B' }}> </span></h1>
                  <p className="MsoNormal" style={{ margin: '0cm', textAlign: 'left', textIndent: '0cm', lineHeight: '107%' }}><b><span style={{ color: '#2B2B2B' }}>&nbsp;</span></b></p>
                  <p className="MsoNormal" style={{ margin: '0cm', textAlign: 'left', textIndent: '0cm', lineHeight: '107%' }}><b><span style={{ color: '#2B2B2B' }}>&nbsp;</span></b></p>
                  <p className="MsoNormal" style={{ marginLeft: '76.75pt', lineHeight: '90%' }}><span style={{ color: '#2B2B2B' }}>12.1. </span>A delay by either party in enforcing any
                    right under this Agreement does not constitute a waiver of that right or any
                    future right.<b><span style={{ color: '#2B2B2B' }}> </span></b></p>
                  <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '9.4pt', marginBottom: '.65pt', marginLeft: '48.15pt', textIndent: '0cm' }}><span style={{ color: '#2B2B2B' }}>12.2. </span>A
                    person who is not a party to this Agreement has no rights under this Agreement.<b><span style={{ color: '#2B2B2B' }}> </span></b></p>
                </div>
                <span style={{ fontSize: '10.0pt', lineHeight: '112%', fontFamily: '"Arial",sans-serif', color: '#1C1C1C' }}><br clear="all" style={{ pageBreakBefore: 'always' }} />
                </span>
                <div className="WordSection2">
                  <p className="MsoNormal" style={{ margin: '0cm', textAlign: 'left', textIndent: '0cm', lineHeight: '107%' }}>&nbsp;</p>
                </div>
              </div>
            </div>
          </div>
        </Section>
      </Block>
    </div>
  )
}

export default TnC
